export default {
  "account": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersaccount"])},
    "calendarsettings": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender instellingen"])},
      "changeteachercalendar": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recht aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "changeteachergroupcalendar": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "changeusercalendars": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kan je een persoonlijk kleur geven aan jouw persoonlijke agenda."])},
      "defaultcalendars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen zichtbare kalenders in de applicatie kalender"])},
      "defaultcalendarsdescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kan je aanvinken welke kalenders je zichtbaar wil zien wanneer je naar de applicatie kalender (via de startpagina van Smartschool of via de website Toekomstperspectief) gaat."])},
      "defaultcalendarshome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen zichtbare kalenders op de startpagina van toekomstperspectief"])},
      "defaultcalendarshomedescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kan je aanvinken welke kalenders je zichtbaar wil zien op de startpagina van toekomstperspectief. De schoolagenda (alle ingevoerde lessen/agenda’s via de handelingsplanning) is standaard zichtbaar voor de klassen waar jij les in geeft. Via daar kan je snel naar de dagdagelijkse evaluatie gaan."])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan, probeer opnieuw"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faut"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechten"])},
      "permissionsexplination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kan je je agenda delen met specifieke personen of met reeds gemaakte groepen op Toekomstperspectief."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen aangepast"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
      "teachergroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkrachtgroepen"])},
      "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkrachten"])}
    },
    "changepassword": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord veranderen"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord niet correct"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
      "newpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord:"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidig wachtwoord:"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wachtwoord is veranderd"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
    },
    "forgotpassword": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstuur email"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan."])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een email gestuurd met een link om uw wachtwoord aan te passen."])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul uw email hier onder in en er wordt u een link gestuurd om het te veranderen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten?"])}
    },
    "forgotpasswordreset": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan, probeer opnieuw."])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord:"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord aangepast"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord instellen"])}
    },
    "login": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email en wachtwoord combinatie niet gevonden"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
      "forgotpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten?"])},
      "forgotpasswordlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset wachtwoord"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met Google Workspace account"])},
      "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontwikkeld in samenwerking met De Zeeparel"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord:"])},
      "smartschool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met Smartschool account"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent aangemeld"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
      "toekomstperspectiefaccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of meld aan met email-adres"])}
    },
    "logout": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent afgemeld"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afmelden"])}
    },
    "switch": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen toegang"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent aangemeld als de nieuwe gebruiker"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
    }
  },
  "admin": {
    "archive": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archief"])},
      "reportsarchive": {
        "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangemaakt op"])},
        "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "generated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegenereerd"])},
        "schoolyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schooljaar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gearchiveerde rapporten"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport"])},
        "types": {
          "ihp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAC"])},
          "ihpoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAC overzicht"])},
          "matrixclass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix klas"])},
          "matrixstudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix leerling"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbekend"])}
        }
      },
      "reportscompetence": {
        "schoolyear": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Schooljaar: ", _interpolate(_named("yearstart")), "-", _interpolate(_named("yearend"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competentie rapporten"])}
      },
      "reportsihp": {
        "schoolyear": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Schooljaar: ", _interpolate(_named("yearstart")), "-", _interpolate(_named("yearend"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAC rapporten"])}
      }
    },
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheerder functies"])},
    "checkentry": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controle ingave beheerder"])}
    },
    "log": {
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie"])},
      "actions": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afmelden"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijken"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])}
      },
      "actiontype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie type"])},
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logboek"])},
      "ipaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ip adres"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkracht"])},
      "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdstip"])}
    },
    "matrixgraphs": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix grafieken"])},
      "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe verder naar buiten hoe meer er aan een onderdeel gewerkt moet worden volgens de huidige matrix."])},
      "selectexplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik de Ctrl toets om meerdere te selecteren."])},
      "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerlingen:"])}
    },
    "spread": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spreiding"])}
    }
  },
  "agenda": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schoolagenda"])},
    "entry": {
      "backbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingave"])},
      "classtimes": {
        "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik de Ctrl toets om meerdere te selecteren"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesuren:"])}
      },
      "copybutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiëren"])},
      "copymodal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schoolagenda item kopiëren"])}
      },
      "delete": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schoolagenda item verwijderd"])}
      },
      "deletebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
      "editbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
      "editmodal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schoolagenda item bewerken"])}
      },
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er iets iets mis misgegaan bij het bewaren, probeer opnieuw"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
      "override": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles selecteren"])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herlaad"])},
      "savebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewaren"])},
      "secondstep": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar volgende stap"])}
      },
      "secondstepedit": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar volgende stap"])}
      },
      "subject": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesonderwerp:"])}
      },
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item toegevoegd"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
      "table": {
        "classtimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesuren: "])}
      },
      "textareatitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerinhouden:"])}
    },
    "entryov3": {
      "attachmentinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er kunnen pas bijlagen toegevoegd worden na het bewaren."])},
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingave OV3"])},
      "classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokaal"])},
      "classtimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesuren:"])},
      "copy": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item gekopieerd."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "copybutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieer"])},
      "copymodal": {
        "newdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe datum:"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiëren"])}
      },
      "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vak:"])},
      "dateselection": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik de cursortoetsen om door kalenderdatums te navigeren"])},
        "nodateselected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen datum geselecteerd"])}
      },
      "delete": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item verwijderd."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "editbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
      "editmodal": {
        "deleteattachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
        "overrideall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doelen klasniveau:"])}
      },
      "evaluate": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaties bewaard"])}
      },
      "evaluatebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueren"])},
      "evaluatemodal": {
        "overrideall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overschrijf alles:"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueer"])}
      },
      "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerinhouden:"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "modal": {
        "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijlagen:"])},
        "file": {
          "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bladeren"])},
          "dropplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleep bestand hier"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek een bestand of sleep het hier."])}
        },
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])}
      },
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw item"])},
        "nocourseselected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen vak geselecteerd."])},
        "overrideall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doelen klasniveau:"])}
      },
      "onlymyselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn selectie"])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herlaad"])},
      "savebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewaren"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesonderwerp:"])},
      "table": {
        "classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassen"])},
        "classtimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesuren"])},
        "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vak"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesonderwerp"])},
        "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkracht"])}
      },
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassing bewaard"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      }
    },
    "report": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])},
      "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vak"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "gotoentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar ingave pagina"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkracht"])}
    },
    "reportclass": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht per klas"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas"])},
      "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vak"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangemaakt op"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkracht"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatst aangepast op"])}
    },
    "reportteachers": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht per leerkracht"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas"])},
      "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vak"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangemaakt op"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatst aangepast op"])}
    }
  },
  "attendance": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheden"])},
    "informatexport": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteer informat bestand"])}
    }
  },
  "calendar": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])},
    "dateselection": {
      "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik de cursortoetsen om door kalenderdatums te navigeren"])},
      "nodateselected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen datum geselecteerd"])}
    },
    "edit": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitnodiging geaccepteerd"])},
      "createdby": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aangemaakt door: ", _interpolate(_named("name"))])},
      "date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum: ", _interpolate(_named("time"))])},
      "displayonly": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitnodiging geaccepteerd"])},
        "end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Einde: ", _interpolate(_named("time"))])},
        "invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgenodigd"])},
        "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Titel: ", _interpolate(_named("name"))])},
        "room": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lokaal: ", _interpolate(_named("room"))])},
        "start": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Begin: ", _interpolate(_named("time"))])}
      },
      "endtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einde: "])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel:"])},
      "notaccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitnodiging niet geaccepteerd"])},
      "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhaal"])},
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokaal:"])},
      "savebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewaren"])},
      "starttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin: "])},
      "teachersinvited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkrachten uitnodigen:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk item"])}
    },
    "new": {
      "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender:"])},
      "date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum: ", _interpolate(_named("time"))])},
      "end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Einde: ", _interpolate(_named("time"))])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen lokaal geselecteerd"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faut"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel:"])},
      "repeatdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal dagen tussen herhaling"])},
      "repeattimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal keer te herhalen"])},
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokaal:"])},
      "savebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewaren"])},
      "start": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Begin: ", _interpolate(_named("time"))])},
      "starttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin: "])},
      "teachersinvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkrachten uitnodigen:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw item"])}
    },
    "table": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhoud"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einde"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])}
    }
  },
  "competence": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competentiescore"])},
    "discussion": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bespreking"])},
      "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vak"])},
      "savebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewaar"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassing bewaard"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkracht"])}
    },
    "entry": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingave"])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herlaad data"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpssingen bewaard"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
    },
    "report": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport"])},
      "printbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afdrukken"])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herlaad data"])}
    }
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan bij het bewaren."])},
  "file": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier"])},
    "career": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schoolloopbaan"])},
      "year1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaar 1"])},
      "year2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaar 2"])},
      "year3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaar 3"])},
      "year4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaar 4"])},
      "year5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaar 5"])},
      "year6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaar 6"])},
      "year7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaar 7"])},
      "year8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaar 8"])}
    },
    "charts": {
      "competence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergelijking competentiescore."])},
      "matrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergelijking aantal ingevulde doelen per categorie voor de leerling met de meest recente matix van de leerling."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafieken"])}
    },
    "class": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doelen overzicht"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wil je weten welke doelen onder de groepen behoren? "])}
    },
    "note": {
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe nota"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota aangepast"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
      "teacher": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("TeacherFirstName")), " ", _interpolate(_named("TeacherLastName")), " schreef op ", _interpolate(_named("date")), ":"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota's"])}
    },
    "progress": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voortgang"])}
    },
    "reportsarchive": {
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangemaakt op"])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
      "generated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegenereerd"])},
      "schoolyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schooljaar"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gearchiveerde rapporten"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport"])},
      "types": {
        "competence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competentiescore"])},
        "ihp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAC"])},
        "ihpoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAC overzicht"])},
        "matrixclass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix klas"])},
        "matrixstudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix leerling"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbekend"])}
      }
    },
    "reportscompetence": {
      "schoolyear": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Schooljaar: ", _interpolate(_named("yearstart")), "-", _interpolate(_named("yearend"))])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competentie rapporten"])}
    },
    "reportsihp": {
      "schoolyear": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Schooljaar: ", _interpolate(_named("yearstart")), "-", _interpolate(_named("yearend"))])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAC rapporten"])}
    },
    "reportspreview": {
      "ihp": {
        "parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport voor ouders"])},
        "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport voor de school"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAC"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afdrukvoorbeeld rapporten"])}
    },
    "selectedgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geselecteerde doelen"])},
    "student": {
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas:"])},
      "openStudentPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open leerlingportaal"])},
      "startyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginschooljaar:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegevens"])}
    }
  },
  "gwp": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepswerkplan"])},
    "printbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afdrukken"])},
    "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herlaad"])}
  },
  "help": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "email": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support (AT) toekomstperspectief.be"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor vragen kan u mailen naar bovenstaand email adres."])}
    },
    "list": {
      "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakken"])},
      "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doelen"])},
      "subgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doelen en subdoelen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzichten van:"])}
    },
    "statustitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status scanners"])},
    "table": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste update"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])}
  },
  "home": {
    "agenda": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schoolagenda"])}
    },
    "agendatoday": {
      "classitem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("classname")), " : ", _interpolate(_named("course"))])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schoolagenda van vandaag"])}
    },
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startpagina"])},
    "openparameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn open parameter suggesties."])},
    "opensubgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn open subdoel suggesties."])},
    "teacher": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstName")), " ", _interpolate(_named("lastName"))])},
    "teachersonline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online leerkrachten"])},
    "useropenparameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft nog open parameter suggesties."])},
    "useropensubgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft nog open subdoel suggesties."])},
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welkom ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName"))])}
  },
  "ihp": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAC"])},
    "checkentry": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controle ingave"])}
    },
    "entry": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingeven"])},
      "collapsebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openen / sluiten"])},
      "delete": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluatie verwijderd"])}
      },
      "deletebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
      "duplicate": {
        "allclasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle klassen"])},
        "classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassen:"])},
        "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerlingen:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekopieerd"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toepassen op meerdere"])}
      },
      "duplicatebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliceren"])},
      "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aanpassingen zijn niet bewaard. fout code: ", _interpolate(_named("error"))])},
      "errorlevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De niveaugroep is niet ingevuld."])},
      "errorparameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen parameter geselecteerd."])},
      "errorpermissiondenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang geweigerd."])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
      "gotoreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar het rapport van deze leerling"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer niveaugroep:"])},
      "matrixvalue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Selecteer Matrix waarde korte termijndoel ( huidige waarde: ", _interpolate(_named("current")), " ):"])},
      "newparameter": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorstel is bewaard"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "newsubgoal": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorstel is bewaard"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "nextperiod": {
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan, je aanpassing is niet bewaard."])},
        "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout!"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meegenomen naar volgende periode"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meenemen naar volgende periode"])}
      },
      "nextperiodbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meenemen naar volgende periode"])},
      "nextstudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende leerling"])},
      "noteinternal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne opvolging leerkracht:"])},
      "notereport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapportmededeling:"])},
      "notime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderdeel kwam niet aan bod"])},
      "parameter1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer parameter 1:"])},
      "parameter2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer parameter 2:"])},
      "parameter3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer parameter 3:"])},
      "previousstudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige leerling"])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herlaad data"])},
      "reusewarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit subdoel is al vaak gebruikt geweest in dit vak."])},
      "reusewarningov3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit doel is al vaak gebruikt geweest in dit vak."])},
      "savebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewaren"])},
      "showonreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weergeven op rapport"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassingen zijn bewaard."])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
      "suggestnew": {
        "parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe parameter:"])},
        "subgoal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw subdoel."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw subdoel of parameter"])}
      },
      "suggestnewbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorstellen parameter of subdoel"])}
    },
    "entrycontent": {
      "agendacount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aantal keer in agenda: ", _interpolate(_named("count"))])},
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingeven leerinhouden"])},
      "cardtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("student")), " - ", _interpolate(_named("goal"))])},
      "delete": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluatie verwijderd."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "endevaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eindevaluatie"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassing niet bewaard."])},
      "errorpermissiondenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang geweigerd."])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
      "evaluations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tussentijdse/dagelijkse evaluatie"])},
      "globalparameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik werk met de ondersteunende parameters"])},
      "goalmodal": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doelen"])}
      },
      "matrixvaluegoal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("goal")), " ( huidige waarde: ", _interpolate(_named("current")), " ):"])},
      "matrixvalueparameter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("subgoal")), " ( huidige waarde: ", _interpolate(_named("current")), " ):"])},
      "newscorecomment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score commentaar:"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueren:"])},
      "otherparameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik selecteer extra parameters:"])},
      "parameter1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter 1:"])},
      "parameter2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter 2:"])},
      "parameter3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter 3:"])},
      "parametermeasures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geselecteerde parameter in ondersteunende maatregelen:"])},
      "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herlaad data"])},
      "savebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewaren"])},
      "score": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niet relevant / niet bereikt / stop we werken er niet meer aan verder"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in ontwikkeling"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voldoende - genoeg voor deze leerling"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leerling is afwezig"])},
        "newscoretypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODO"])}
      },
      "subgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concretiseringen"])},
      "submitnewscore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe tussentijdse evaluatie toevoegen"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassing bewaard"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
      "typeradios": {
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel"])},
        "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerling"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer soort"])}
      }
    },
    "entrymeasures": {
      "addattachmentbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg bijlage toe"])},
      "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijlagen:"])},
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingeven ondersteunende maatregelen"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beeldvorming:"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassing niet bewaard."])},
      "errormissingdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet alles is ingevuld."])},
      "errorpermissiondenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen toegang"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
      "ledgend": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd afbakenen (adhv timetimer/keukenwekker)"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedrags- en ondersteunende pictogrammen en schema's"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werken met daglijn (individueel/klassikaal)"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werken met stappenplannen/checklijsten waarin instructie verwerkt zit "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructie: naam benoemen en instructie laten herhalen"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen fysieke aanrakingen"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkelvoudige opdrachten"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rustige werkplaats (vaste plaats, geen materiaal op de bank, schermen plaatsen)"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invulling dode momenten voorzien"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zorgen voor voldoende afwisseling"])}
      },
      "ledgendbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legende tonen/verbergen"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omwille van"])},
      "parameter1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter 1:"])},
      "parameter2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter 2:"])},
      "parameter3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter 3:"])},
      "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heeft deze leerling nood aan"])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herlaad data"])},
      "savebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewaren"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassing bewaard."])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
      "uploadmodal": {
        "file": {
          "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bladeren"])},
          "dropplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleep bestand hier"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek een bestand of sleep het hier."])}
        },
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijlage toevoegen"])}
      }
    },
    "newreport": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
      "modal": {
        "courseby": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("teacher")), " voor ", _interpolate(_named("course"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])}
      },
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herlaad"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassing bewaard"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
    },
    "overview": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle jaren"])},
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mededelingen"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapportmededeling:"])},
      "noteprivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne opvolging leerkracht:"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkracht: "])}
    },
    "overviewov3": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])},
      "gotoentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar ingave"])},
      "table": {
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel"])}
      }
    },
    "report": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport"])},
      "editbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel: "])},
      "gotoentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar ingave pagina"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat uitleg:"])},
      "noteprivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne rapportering:"])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herlaad rapport"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewaar"])},
      "simplifybutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verberg lijnen die niet op het rapport voor de ouders komen."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassing bewaard"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
    }
  },
  "matrix": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix"])},
    "entry": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix"])},
      "editbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassing niet bewaard."])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout!"])},
      "evaluation": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluatie is bewaard"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "exportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteren als Excel bestand."])},
      "fixbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los problemen op met nieuwe leerling of een leerling die veranderd van klas"])},
      "fixstudent": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Script uitgevoerd"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "ledgend": {
        "abbreviations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afkortingen"])},
        "abbreviationsng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NG: Niets geselecteerd"])},
        "colorcodes": {
          "danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de belangrijkste om mee te werken"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gewoon, geen specifiek werkdoel of reeds aan gewerkt"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er werd in het verleden reeds aan de slag gegaan met dit doel of is reeds bij alle leerlingen V/OK, dus niet meer prioritair te selecteren"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleurcodes"])},
          "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aan te werken, maar niet prioritair"])}
        }
      },
      "ledgendbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legende tonen/verbergen"])},
      "modal": {
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemeen"])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit doel is voor volgende leerlingen ok of voldoende!"])},
        "warningtimesselected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit doel is al vaak geëvalueerd voor deze klas."])}
      },
      "newstudent": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe leerling ingesteld"])}
      },
      "nostudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn geen leerlingen in deze klas."])},
      "nostudentstitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout!"])},
      "note": {
        "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vak:"])},
        "hppe1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["periode:"])},
        "hppe2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["periode:"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking:"])},
        "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritair"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota is bewaard"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "openmodalbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open doelselectie"])},
      "openseccondmodalbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ander doel voor leerling selecteren."])},
      "printbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afdrukken"])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herlaad"])},
      "reportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open rapport"])},
      "savebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewaren"])},
      "setupbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerling instellen."])},
      "simplifybutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereenvoudig"])},
      "studentokgoalmodal": {
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel:"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ander doel voor leerling selecteren."])}
      }
    },
    "report": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel"])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herlaad"])}
    }
  },
  "matrixupdate": {
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstName")), " heeft een waarde aangepast"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
  },
  "messages": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichten"])}
  },
  "navbar": {
    "admin": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archief"])},
      "checkadmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controle ingave beheerder"])},
      "ihp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individueel handelingsplan bewerken"])},
      "log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logboek"])},
      "matrixgraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix grafieken"])},
      "spread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spreiding"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheerder functies"])}
    },
    "agenda": {
      "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingave"])},
      "entrymultiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingave over meerdere klassen"])},
      "entryov3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingeven OV3"])},
      "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])},
      "reportclass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht per klas"])},
      "reportteachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht per leerkracht"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schoolagenda"])}
    },
    "alerts": {
      "calendarinvitation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Agenda uitnodiging. ", _interpolate(_named("title")), " op ", _interpolate(_named("date")), " door ", _interpolate(_named("username"))])},
      "calendarupdate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Agenda uitnodiging aangepast. ", _interpolate(_named("title")), " op ", _interpolate(_named("date")), " door ", _interpolate(_named("username"))])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldingen"])}
    },
    "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheden"])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])},
    "competence": {
      "discussion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bespreking"])},
      "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingeven"])},
      "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competentiescore"])}
    },
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier leerling"])},
    "gwp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepswerkplan"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startpagina"])},
    "ihp": {
      "checkentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controle ingave"])},
      "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingeven"])},
      "entrycontent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingeven leerinhouden"])},
      "entrymeasures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingeven ondersteunende maatregelen"])},
      "newreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw rapport"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])},
      "overviewov3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht en evaluatie"])},
      "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport"])},
      "reportov3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OV3 rapport"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individueel aangepast curriculum"])}
    },
    "matrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix"])},
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichten"])},
    "pos": {
      "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellingen vandaag"])},
      "ordersrange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellingen meerdere dagen"])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producten"])},
      "studentoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerling overzicht"])},
      "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerlingen"])},
      "teacheroverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkrachten overzicht"])},
      "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkrachten"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassa"])},
      "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellingen vandaag"])},
      "topups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijladingen vandaag"])},
      "topupsrange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijladingen meerdere dagen"])},
      "webpos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online kassa"])}
    },
    "pwreset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartschool account wachtwoord reset"])},
    "reports": {
      "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle vakken"])},
      "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht per domein"])},
      "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereer rapport pdf's"])},
      "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle doelen"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht per periode"])},
      "subgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle subdoelen"])},
      "suggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht subdoelvoorstellen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])}
    },
    "settings": {
      "calendars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalenders"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontwikkelingsdoel"])},
      "classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassen"])},
      "classschedules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesroosters"])},
      "classtimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesuren"])},
      "competenceanchors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competentie ankers"])},
      "competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competenties"])},
      "coursegroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vak groepen"])},
      "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakken"])},
      "educationforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opleidingsvormen"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemene instellingen"])},
      "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doelen"])},
      "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveaugroepen"])},
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichten"])},
      "moments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluatiemomenten"])},
      "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
      "parameterssuggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter voorstellen"])},
      "perspectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domeinen"])},
      "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalen"])},
      "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerlingen"])},
      "subgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdoelen"])},
      "subgoalssuggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdoel voorstellen"])},
      "teachergroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkrachtgroepen"])},
      "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkrachten"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])}
    },
    "socketstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real time updates status"])},
    "socketviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal personen dat op deze exacte pagina zijn"])},
    "user": {
      "calendarsettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender instellingen"])},
      "changepassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord veranderen"])},
      "logoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afmelden"])},
      "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verander gebruiker"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])}
    }
  },
  "notfound": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breng me naar de startpagina"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, er is iets misgegaan, De gevragde pagina is niet gevonden!"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 Niet gevonden"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oeps!"])}
  },
  "pagewatching": {
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstName")), " bekijkt deze pagina nu ook."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
  },
  "pos": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassa"])},
    "orders": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellingen"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas"])},
      "delete": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bestelling is verwijderd."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "deletebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder bestelling"])},
      "exportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteren"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "orderid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling nummer"])},
      "product": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " € ", _interpolate(_named("price"))])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producten"])},
      "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdstip"])},
      "total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bestellingen totaal € ", _interpolate(_named("total"))])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoon"])}
    },
    "ordersrange": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellingen meerdere dagen"])},
      "enddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einddatum:"])},
      "exportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteren"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "startdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begindatum:"])}
    },
    "products": {
      "addoption": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optie toegevoegd"])}
      },
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product gearchiveerd"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producten"])},
      "changeproductterminal": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepast"])}
      },
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product aanmaken mislukt"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
      "modal": {
        "addcredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarde toevoegen"])},
        "addoption": {
          "defaultenabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard aan"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam: "])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs: "])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optie toevoegen"])}
        },
        "options": {
          "defaultenabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard aan"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opties"])}
        },
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs"])},
        "reducecredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarde weghalen"])},
        "showinkiosk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In studenten kiosk tonen"])},
        "terminals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassa toestellen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product bewerken"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "new": {
        "addcredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarde toevoegen"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam:"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs"])},
        "reducecredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarde weghalen"])},
        "showinkiosk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In studenten kiosk tonen"])}
      },
      "picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product aangemaakt"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      }
    },
    "studentoverview": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerling overzicht"])},
      "charge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Op ", _interpolate(_named("time")), " is er een aankoop gedaan van ", _interpolate(_named("value")), "."])},
      "exportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteer"])},
      "product": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " € ", _interpolate(_named("price"))])},
      "topup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Op ", _interpolate(_named("time")), " is er ", _interpolate(_named("value")), " bijgeladen."])},
      "total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Huidige waarde op kaart € ", _interpolate(_named("total"))])}
    },
    "students": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerlingen overzicht"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas"])},
      "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidige waarde kaart"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type op te zoeken"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])}
    },
    "teacheroverview": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkracht overzicht"])},
      "charge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Op ", _interpolate(_named("time")), " is er een aankoop gedaan van ", _interpolate(_named("value")), "."])},
      "exportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteer"])},
      "product": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " € ", _interpolate(_named("price"))])},
      "topup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Op ", _interpolate(_named("time")), " is er ", _interpolate(_named("value")), " bijgeladen."])},
      "total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Huidige waarde op kaart € ", _interpolate(_named("total"))])}
    },
    "teachers": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkrachten overzicht"])},
      "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidige waarde kaart"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type op te zoeken"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])}
    },
    "topups": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijladingen"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas"])},
      "delete": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijlading verwijderd"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "deletebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
      "orderid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling nummer"])},
      "product": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " € ", _interpolate(_named("price"))])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producten"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkracht"])},
      "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdstip"])},
      "total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ontvangen totaal € ", _interpolate(_named("total"))])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoon"])}
    },
    "topupsrange": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijladingen meerdere dagen"])},
      "deletebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
      "enddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einddatum:"])},
      "exportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteren"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "startdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begindatum:"])}
    },
    "webpos": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online kassa"])},
      "cart": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
        "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opties"])},
        "order": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bestellen € ", _interpolate(_named("price"))])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winkelwagen"])}
      },
      "checkout": {
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling plaatsen"])},
        "student": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Leerling: ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName"))])},
        "teacher": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Leerkracht: ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellen"])}
      },
      "errorcredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De leerling heeft niet genoeg krediet meer."])},
      "errorgeneric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout!"])},
      "modal": {
        "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toevoegen € ", _interpolate(_named("price"))])},
        "option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " (€ ", _interpolate(_named("price")), ")"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Product: ", _interpolate(_named("productname"))])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type hier om te zoeken"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je bestelling is geplaatst."])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
    }
  },
  "pwreset": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartschool wachtwoord reset"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas"])},
    "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout!"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
    "modal": {
      "mainaccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdaccount"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verander wachtwoord van ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName"))])}
    },
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken"])},
    "smusername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartschool gebruikersnaam"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord is veranderd"])},
    "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
  },
  "reports": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafieken"])},
    "courses": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakken"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])}
    },
    "download": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download rapporten"])},
      "buttonsinglereport": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Download ", _interpolate(_named("reportname")), " rapport voor ", _interpolate(_named("student"))])},
      "popupnotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als niet alles gedownload wordt kan het zijn dat de browser de pop-ups blokkeert."])},
      "reports": {
        "competence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competentiescore rapport"])}
      }
    },
    "generate": {
      "allnotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle nota's"])},
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporten genereren"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereer rapporten, Dit kan een tijd duren!"])},
      "classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassen"])},
      "classfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas rapport"])},
      "competence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competentiescore rapport"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan, heeft u wel een klas geselecteerd?"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
      "ihp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAC Rapport"])},
      "ihpoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAC overzicht"])},
      "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intern rapport"])},
      "matrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix"])},
      "ov3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OV3 rapport"])},
      "parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport voor ouders"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode:"])},
      "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard"])},
      "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerlingen"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporten aangemaakt"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit genereert pdf bestanden voor de geselecteerde rapporten en leerlingen, Dit kan een hele tijd duren en wordt best enkel gebruikt op het einde van een periode om een archief aan te maken. Het beste is om niet alle klassen in 1 keer te doen."])}
    },
    "goals": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doelen"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
      "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel"])}
    },
    "subgoals": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdoelen"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
      "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein"])},
      "evaluationtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EvaluatieType"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdoelen"])}
    }
  },
  "routes": {
    "admincheckentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheerder controle ingave"])},
    "adminlog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheerder logboek"])},
    "adminspread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheerder Spreiding"])},
    "agendaentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schoolagenda Ingave"])},
    "agendaentrymultiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schoolagenda ingave over meerdere klassen"])},
    "agendaentryov3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schoolagenda OV3 Ingave"])},
    "agendareport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenda Rapport"])},
    "agendareportteacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenda overzicht per leerkracht"])},
    "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheden"])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])},
    "changepassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker verander wachtwoord"])},
    "competencediscussion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competentiescore bespreking"])},
    "competenceentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competentiescore Ingave"])},
    "competencereport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competentiescore rapport"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier leerling"])},
    "gwp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepswerkplan"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startpagina"])},
    "ihpcheckentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAC Controle ingave"])},
    "ihpentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAC Ingave"])},
    "ihpentrycontent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAC Leerinhouden ingave"])},
    "ihpentrymeasures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAC Ingeven ondersteunende maatregelen"])},
    "ihpoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAC Overzicht"])},
    "ihpoverviewov3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAC OV3 Overzicht en evaluatie"])},
    "ihpreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAC Rapport"])},
    "matrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix"])},
    "matrixreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix rapport"])},
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichten"])},
    "posorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassa Bestellingen"])},
    "posordersrange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassa Bestellingen meerdere dagen"])},
    "posproducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassa Producten"])},
    "posstudentoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassa Leerling overzicht"])},
    "posstudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassa Leerlingen overzicht"])},
    "postopups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassa Bijladingen"])},
    "postopupsrange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassa bijladingen meerdere dagen"])},
    "poswebpos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassa Online kassa"])},
    "pwreset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartschool wachtwoord reset"])},
    "reportscourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafieken vakken"])},
    "reportsgenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereer rapporten"])},
    "reportsgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafieken doelen"])},
    "reportssubgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafieken subdoelen"])},
    "settingsclasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen klassen"])},
    "settingsclasstimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen lesuren"])},
    "settingscompetenceanchors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen competentie ankers"])},
    "settingscompetences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen competenties"])},
    "settingscourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen vakken"])},
    "settingsgeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen algemeen"])},
    "settingsgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen doelen"])},
    "settingsmessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen berichten"])},
    "settingsparameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen parameters"])},
    "settingsparameterssuggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen parameter voorstellen"])},
    "settingsstudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen leerlingen"])},
    "settingssubgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen subdoelen"])},
    "settingssubgoalssuggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen subdoel voorstellen"])},
    "settingsteachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen leerkrachten"])},
    "usercalendarsettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker kalender instellingen"])},
    "userswitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support verander van account"])}
  },
  "select": {
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer klas"])},
    "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer vak"])},
    "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer domein"])},
    "evaluationmoment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer evaluatie moment"])},
    "evaluationmomentcompetence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer evaluatiemoment"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer doel"])},
    "nofilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen filter"])},
    "perspective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer domein"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer leerling"])},
    "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer leerkracht"])}
  },
  "settings": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
    "calendars": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender gearchiveerd"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalenders"])},
      "changeteachercalendar": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "changeteachergroupcalendar": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleur"])},
      "enablecalendarforall": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegevoegd bij alle leerkrachten"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "enableforall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aan alle leerkrachten toevoegen"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "modal": {
        "teachergroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkrachtgroepen"])},
        "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkrachten"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk kalender"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe kalender toevoegen"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender aangemaakt"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "permissions": {
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lezen, schrijven en verwijderen"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen toegang"])},
        "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lezen"])},
        "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lezen en schrijven"])}
      },
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender is aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      }
    },
    "categories": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontwikkelingsdoel"])},
      "changecategorycourse": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleur"])},
      "courserestricted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vak gelimiteerd"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "hiddeningraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verborgen in grafieken"])},
      "modal": {
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleur:"])},
        "courseRestricted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vak gelimiteerd"])},
        "hiddeningraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verberg ontwikkelingsdoel in grafieken"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontwikkelingsdoel bewerken"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw ontwikkelingsdoel toevoegen"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontwikkelingsdoel aangemaakt"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontwikkelingsdoel aangepast."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      }
    },
    "classes": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas gearchiveerd"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassen"])},
      "changeclasscourse": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "changeteacherclass": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "hascourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft vak(ken)"])},
      "hasstudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft leerlingen"])},
      "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pictogram"])},
      "iconupdate": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icoon aangepast"])}
      },
      "modal": {
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klastitularis: "])},
        "file": {
          "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bladeren"])},
          "dropplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleep bestand hier"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek een bestand of sleep het hier."])}
        },
        "icon": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload nieuw icoon"])}
        },
        "icons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iconen verbergen op rapport."])},
        "matrixhidegoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën een score geven in de matrix"])},
        "schoolyearstart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deelwerkplan:"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk klas"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe klas"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas toegevoegd"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "orderupdate": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgorde aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "smartschoolsynced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas gesynchroniseerd met smartschool, sommige wijzigingen kunnen overschreven worden bij de volgende synchronisatie."])},
      "sortmodal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgorde aanpassen"])}
      },
      "update": {
        "iconsuccesstitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["succes"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "uploadmodal": {
          "iconsuccesstitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijlage geüpload"])}
        }
      }
    },
    "classschedules": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesroosters"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])}
    },
    "classtimes": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het lesuur is gearchiveerd."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesuren"])},
      "endtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einde"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk lesuur"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw lesuur"])},
        "endtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einde:"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam: "])},
        "starttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesuur toegevoegd"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "starttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin"])},
      "update": {
        "endtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einde:"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam: "])},
        "starttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesuur aangepast."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbeter hiermee enkel spelingsfouten anders kloppen de rapporten niet meer!"])}
      }
    },
    "competenceanchors": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competentie ankers"])},
      "competence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competentie"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk competentie anker"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw competentie anker"])},
        "competence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competentie:"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competentie anker aangemaakt"])}
      },
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode"])},
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competentie anker aangepast"])}
      }
    },
    "competences": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competenties"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleur"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk competentie"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe competentie"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleur"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competentie aangemaakt"])}
      },
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competentie aangepast"])}
      }
    },
    "courses": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vak gearchiveerd"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakken"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleur"])},
      "coursegroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakgroep"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "iconupdate": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["succes"])}
      },
      "modal": {
        "alwaysallgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altijd alle doelen weergeven in individueel handelingsplan voor dit vak."])},
        "educationform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderwijsvorm"])},
        "file": {
          "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bladeren"])},
          "dropplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleep bestand hier"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek een bestand of sleep het hier."])}
        },
        "icon": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload nieuw icoon"])}
        },
        "ihponlyshownote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon enkel het rapport nota veld op het IAC rapport."])},
        "namealert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas hier enkel spellingfouten in de naam aan, Als u u de naam aanpast verandert dit op alle historische data ook."])},
        "perspective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein:"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk vak"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw vak"])},
        "educationform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderwijsvorm"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vak toegevoegd"])}
      },
      "orderupdate": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgorde aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "perspective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein"])},
      "update": {
        "iconsuccesstitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["succes"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vak aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      }
    },
    "domains": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domeinen"])},
      "changeperspectiveclass": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein aangepast"])}
      },
      "changeperspectivecourse": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein aangepast"])}
      },
      "classrestricted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas gelimiteerd."])},
      "courserestricted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vak gelimiteerd."])},
      "educationforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opleidingsvorm"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "modal": {
        "classRestricted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkel tonen voor geselecteerde klassen."])},
        "courseRestricted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkel tonen voor geselecteerde vakken."])},
        "matrixsubscores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix Ontwikkelingsdoel score berekenen."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk domein"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw domein"])},
        "educationform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opleidingsvorm:"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein aangemaakt"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein aangepast"])}
      }
    },
    "general": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemene instellingen"])},
      "broadcast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type hier tekst om naar alle aangemelde gebruikers."])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "modal": {
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uit"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instelling aanpassen"])},
        "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarde:"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omschrijving"])},
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instelling aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarde"])}
    },
    "goals": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doelen"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
      "changegoalcourse": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassing bewaard"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleur"])},
      "courserestricted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vak gelimiteerd"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel"])},
      "modal": {
        "schoolyearstart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deelwerkplan:"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk doel"])}
      },
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw doel"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontwikkelingsdoel:"])},
        "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein:"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel aangemaakt"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "perspectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein"])},
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      }
    },
    "messages": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht gearchiveerd"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichten"])},
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht aanpassen"])}
      },
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw bericht"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht aangemaakt"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel:"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      }
    },
    "parameters": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter gearchiveerd"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
      "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk parameter"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe parameter"])},
        "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein:"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter toegevoegd"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "update": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes "])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbeter hiermee enkel spelingsfouten anders kloppen de rapporten niet meer!"])}
      }
    },
    "parameterssuggestions": {
      "add": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter toegevoegd."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters voorstellen"])},
      "delete": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter voorstel verwijderd."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkracht"])},
      "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdstip"])}
    },
    "rooms": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokaal gearchiveerd"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalen"])},
      "classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leslokaal"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk lokaal"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw lokaal"])},
        "classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leslokaal"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokaal aangemaakt"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "orderupdate": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgorde aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "resourcecalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In kalender"])},
      "sortmodal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgorde aanpassen"])}
      },
      "update": {
        "classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leslokaal"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam:"])},
        "resourcecalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon in kalender"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokaal aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      }
    },
    "students": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerling gearchiveerd."])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerlingen"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas"])},
      "courses": {
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "exportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteer niet geconfigureerde accounts"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
      "imageupdate": {
        "iconsuccesstitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeelding aangepast"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
      "modal": {
        "file": {
          "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bladeren"])},
          "dropplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleep een afbeelding naar hier."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een afbeelding om te uploaden"])}
        },
        "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op internaat"])},
        "picture": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto veranderen"])}
        },
        "smartschoolignoreclass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de klas niet aan met de smartschool synchronisatie"])},
        "subaccounts": {
          "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam:"])},
          "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam:"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw subaccount"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type:"])}
        },
        "subclasses": {
          "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas:"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas toevoegen"])}
        },
        "takesbus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neemt de schoolbus"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk leerling"])}
      },
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe leerling"])},
        "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas: "])},
        "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam: "])},
        "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam: "])},
        "startyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginschooljaar: "])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerling toegevoegd"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "subaccount": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassing bewaard"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "subaccounts": {
        "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
        "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
        "setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingesteld of code"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soort gebruiker"])},
        "types": {
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ander"])},
          "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouder"])},
          "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerling"])}
        }
      },
      "subclass": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassing bewaard"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "subclasses": {
        "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klas"])},
        "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdklas"])}
      },
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerling aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      }
    },
    "subgoals": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdoel gearchiveerd."])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdoelen"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleur"])},
      "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel"])},
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdoel bewerken"])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbeter enkel spelling en zins opbouw fouten hier anders kloppen de rapporten niet meer."])}
      },
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw subdoel"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie: "])},
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek in doelen:"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel: "])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdoel: "])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw subdoel aangemaakt."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "perspectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein"])},
      "subgoal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdoel"])},
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdoel aangepast"])}
      }
    },
    "subgoalssuggestions": {
      "add": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdoel toegevoegd."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdoel voorstellen"])},
      "delete": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorstel verwijderd."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel"])},
      "modal": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdoel:"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdoel toevoegen"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkracht"])},
      "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdstip"])}
    },
    "teachergroups": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkrachtgroep gearchiveerd"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkrachtgroepen"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk leerkrachtgroep"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe leerkrachtgroep"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkrachtgroep aangemaakt"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "update": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkrachtgroep aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      }
    },
    "teachers": {
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheerder"])},
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkrachten"])},
      "changeteacherclass": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "changeteachercourse": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassing bewaard"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "changeteacherteachergroup": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type om te zoeken"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
      "locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geblokkeerd"])},
      "modal": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkracht is actief (kan aanmelden)"])},
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkracht is beheerder"])},
        "readonly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De leerkracht kan enkel lezen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk Leerkracht"])}
      },
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe leerkracht"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email: "])},
        "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam: "])},
        "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam: "])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkracht aangemaakt"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      },
      "readonly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleen lezen"])},
      "smartschoolsynced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkracht gesynchroniseerd met smartschool, sommige wijzigingen kunnen overschreven worden bij de volgende synchronisatie."])},
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerkracht aangepast"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
      }
    }
  },
  "studentlink": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snelle links:"])}
  },
  "tiptap": {
    "imageupload": {
      "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bladeren"])},
      "dropplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleep bestand hier"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek een bestand of sleep het hier."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    }
  }
}